import React from 'react';
import { graphql } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'gatsby';
import { PageMeta } from '@components/pageMeta';
import { SanityImage } from '@components/sanity/sanityImage';
import { useEventQuery } from '@querys/useEventQuery';
import SimplePortableText from '@components/sanity/simplePortableText';
import Layout from '@components/layout';

const OtherEvents = ({ currentEvent }) => {
  const { allSanityEvent } = useEventQuery();

  //filter allSanityEvent.nodes.slug.current !== currentEvent
  const otherEvents = allSanityEvent.nodes
    .filter((event) => event.slug.current !== currentEvent)
    .slice(0, 2);

  return (
    <div className='pb-12 md:pb-32 px-gutter'>
      <div className='flex flex-col md:flex-row md:items-end justify-between'>
        <span className='blockH2'>Other Events</span>
        <Link to='/events' className='text-base font-bold'>
          View all
        </Link>
      </div>

      <div className='flex flex-col md:flex-row gap-x-10 gap-y-16 mt-10 md:mt-20'>
        {otherEvents.map(
          ({ featuredImage, slug, title, dates, location, category }, i) => {
            const { name, colour } = category || {};

            return (
              <div className='relative w-full'>
                <div className='aspect-[5/4] w-full h-auto relative overflow-hidden'>
                  <Link to={`/${slug?.current}`}>
                    <SanityImage
                      image={featuredImage}
                      className='absolute top-0 left-0 w-full h-full object-cover hover:scale-105 transition-all ease-in-out duration-300'
                    />
                  </Link>
                </div>
                <div>
                  <h4 className='blockH4 mb-3 mt-6 md:my-6'>{title}</h4>
                  <span className='blockH6 block'>{dates}</span>
                  <span className='blockH6 block'>{location}</span>
                </div>

                <div
                  style={{ backgroundColor: colour ? colour.hex : '#fff' }}
                  className='absolute top-6 left-4 py-1 px-6 rounded-full'
                >
                  <span className='uppercase text-sm font-bold text-white'>
                    {name}
                  </span>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

const Content = ({ content }) => {
  return (
    <div id='content' className='grid grid-cols-14 pb-24 md:pb-56'>
      <div className='col-start-2 lg:col-start-6 col-end-14'>
        {content && <SimplePortableText text={content} />}
      </div>
    </div>
  );
};

const Intro = ({
  featuredImage,
  category,
  title,
  dates,
  location,
  content,
  ticketsUrl,
}) => {
  return (
    <div className='relative mb-24 lg:pb-64 lg:mb-64'>
      <div className='relative w-full h-auto aspect-[1/1] md:aspect-[16/9]'>
        <SanityImage
          image={featuredImage}
          className='absolute top-0 left-0 w-full h-full object-cover'
        />
        <div className='bg-[#00000030] w-full h-full absolute top-0 left-0' />
      </div>

      <div className='lg:px-gutter'>
        <div
          style={{ backgroundColor: category?.colour?.hex }}
          className='lg:absolute -bottom-12 text-white lg:max-w-[723px] w-full py-10 md:py-20 px-8 md:px-24'
        >
          <div className='py-1 px-6 rounded-full bg-white w-max'>
            <span
              className='uppercase text-sm font-bold'
              style={{ color: category ? category?.colour?.hex : '#000' }}
            >
              {category?.name}
            </span>
          </div>
          <h1 className='blockH1 my-4 md:my-7'>{title}</h1>
          <span className='block blockH5'>{dates}</span>
          <span className='block blockH5'>{location}</span>

          <div className='blockH7 flex gap-x-16 mt-7 font-bold'>
            {content && (
              <ScrollLink
                to={`content`}
                offset={-100}
                smooth={true}
                className='cursor-pointer'
              >
                More Info
              </ScrollLink>
            )}

            {ticketsUrl && (
              <a href={ticketsUrl} target='_blank' rel='noreferrer'>
                Book Tickets
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const EventTemplate = ({ data: { page } }) => {
  const {
    seo,
    featuredImage,
    category,
    title,
    dates,
    location,
    ticketsUrl,
    content,
    slug,
  } = page || {};

  return (
    <Layout reverseHeader>
      <PageMeta {...seo} />
      <Intro
        featuredImage={featuredImage}
        category={category}
        dates={dates}
        location={location}
        title={title}
        content={content}
        ticketsUrl={ticketsUrl}
      />
      <Content content={content} />
      <OtherEvents currentEvent={slug?.current} />
    </Layout>
  );
};

export default EventTemplate;

export const eventQuery = graphql`
  query standardEventQuery($slug: String!) {
    page: sanityEvent(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      ticketsUrl
      slug {
        current
      }
      locationUrl
      location
      featuredImage {
        ...ImageWithPreview
      }
      dates
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      category {
        name
        colour {
          hex
        }
      }
    }
  }
`;
